<template>
  <div class="pa-2" style="border: 1px dotted">
    <div class="d-flex justify-space-between">
      <div>
        Quantity
        <v-btn color="red" icon @click="remove">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <v-btn color="green" icon @click="add">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <div>{{ quantity }}</div>
    </div>
    <div class="d-flex justify-space-between">
      <div>Total</div>
      <div>{{ price * quantity }}</div>
    </div>
    <v-divider class="my-4"></v-divider>

    <div>
      Please make send money of {{ price * quantity }} taka to any of the following mobile banking number and submit
      your transaction id in
      the form below.
    </div>
    <v-list-item v-if="settings.bkash">
      <v-list-item-content>
        <v-list-item-title>bKash: {{ settings.bkash }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="settings.rocket">
      <v-list-item-content>
        <v-list-item-title>Rocket: {{ settings.rocket }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="settings.nagad">
      <v-list-item-content>
        <v-list-item-title>Nagad: {{ settings.nagad }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="my-2"></v-divider>
    <v-form @submit.prevent="submit">
      <v-row class="pa-2">
        <v-col cols="12" class="py-0 px-3">
          <v-text-field label="Name" v-model="name"></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0 px-3">
          <v-text-field label="Address" v-model="address"></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0 px-3">
          <v-text-field label="bKash/Rocket/Nagad number" v-model="phone"></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0 px-3">
          <v-text-field label="Transaction ID" v-model="transaction_number"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn :disabled="false" block type="submit" color="green" class="white--text" rounded>Confirm</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: ['book'],
  data() {
    return {
      quantity: 1,
      settings: '',
      name: '',
      phone: '',
      address: '',
      transaction_number: '',
      rate: '',
    }
  },
  computed: {
    price() {
      if (this.book) {
        return this.book.discount > 0 ? this.book.price - this.book.discount : this.book.price
      } else {
        return 0
      }
    },
    total() {
      return this.price * this.quantity
    },
    form() {
      let formData = new FormData()
      formData.append('book_id', this.$route.params.bookId)
      formData.append('name', this.name)
      formData.append('phone', this.phone)
      formData.append('transaction_number', this.transaction_number)
      formData.append('quantity', this.quantity)
      formData.append('address', this.address)
      formData.append('total', this.total)
      return formData
    }
  },
  methods: {
    add() {
      this.quantity++
    },
    remove() {
      if (this.quantity > 1)
        this.quantity--
    },
    submit() {
      let url = 'book-orders'
      axios.post(url, this.form).then(() => {
        Swal.fire('Success', 'Your order has been placed successfully.', 'success')
        this.reset()
      }).catch(() => {
        Swal.fire('Failed', 'Sorry, something went wrong. Try again.', 'warning')
        this.reset()
      })
    },
    reset() {
      this.quantity = 1
      this.name = ''
      this.phone = ''
      this.address = ''
      this.transaction_number = ''
    }
  },
  created() {
    this.loadSettings()
  }
}
</script>