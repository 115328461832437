<template>
  <v-container>
    <v-row class="mt-2">
      <v-col cols="12" sm="3">
        <v-dialog
            v-model="dialog"
            max-width="800"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-card
                class="pa-4"
                v-bind="attrs"
                v-on="on"
            >
              <h4 class="text-center">Read a little</h4>
              <v-img class="book" :src="book?book.photo:''"></v-img>
            </v-card>
          </template>
          <v-card>
            <v-card-title class="headline grey lighten-2">
              {{ book.title }}
            </v-card-title>
            <v-card-text>
              <div v-for="photo in book.book_photos" :key="photo.id">
                <v-img :src="photo.photo"></v-img>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  @click="dialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" sm="5">
        <div>
          <h2>{{ book.title }}</h2>
          <h4>Price</h4>
          <div v-if="!book.discount">TK {{ book.price }}</div>
          <div v-else>
            TK {{ book.price - book.discount }}
            <del>{{ book.price }}</del>
          </div>
        </div>
        <v-divider class="my-4"></v-divider>
        <h4>Book Description</h4>
        <div v-html="book.description"></div>
      </v-col>
      <v-col cols="12" sm="4">
        <order-book :book="book"></order-book>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import OrderBook from "./OrderBook";

export default {
  components: {OrderBook},
  data() {
    return {
      book: '',
      dialog: false
    }
  },
  computed: {},
  methods: {
    initialize() {
      const url = 'books/' + this.$route.params.bookId
      axios.get(url).then((response) => {
        this.book = response.data
      })
    },
  },
  created() {
    this.initialize()
  }
}
</script>
<style scoped lang="scss">
.box-out {
  z-index: -99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
}

.book {
  background-color: gray;
  transition: all .3s ease-in-out;
  transform-origin: left center 0;
  transform-style: preserve-3d;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
}

.box-out .book::after {
  content: " ";
  display: block;
  opacity: 0;
  position: relative;
  left: 8px;
  transition: all .3s ease;
}

/* ----- hover ----- */
.book:hover {
  cursor: pointer;
  transform: rotateY(-28deg) rotateZ(0deg);
  -webkit-backface-visibility: hidden;
  box-shadow: 1px 3px 2px gray, 10px 0 0 gray;
}

.book:hover::after {
  content: "";
  display: block;
  opacity: 1;
  position: relative;
  left: 8px;
  background: linear-gradient(-180deg, rgba(255, 255, 255, .1) 0%, rgba(255, 255, 255, 0) 60%);
}

.preview {
  border: 1px solid gainsboro;
  padding: 15px;
}

.read-a-little {
  font-size: 1.5rem;
}

.book-item {
  width: 150px;
  height: 270px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 20px 0;
}

.book-description {
  width: 100%;
}

.book-item-name {
  font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;
  font-weight: 700;
  padding-top: 2px;
  font-size: 12px;
  height: 40px;
  color: #000;
}
</style>